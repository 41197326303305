<template>
    <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.7 1.20002C13.3 0.800024 12.7 0.800024 12.3 1.20002L4.8 8.70002L1.7 5.60003C1.3 5.20003 0.7 5.20003 0.3 5.60003C-0.1 6.00003 -0.1 6.60002 0.3 7.00002L4.1 10.8C4.3 11 4.5 11.1 4.8 11.1C5.1 11.1 5.3 11 5.5 10.8L13.7 2.60002C14.1 2.20002 14.1 1.60002 13.7 1.20002Z" :fill="fill"/>
    </svg>
</template>

<script>
import { onMounted, ref, computed } from "vue";
// import A from "./A";

export default {
    props: ["fill"],
    // components: {
    //     A,
    // },
    setup(props, { emit }) {
        // const bool = ref(false);
        const fill = computed(() => {
            if (props.fill) {
                return props.fill
            }

            return "#BDBDBD"
        })

        // const a = (item) => {
        //    console.log(item);
        // };


        return {
            // bool,
            fill,
        }
    },
};
</script>


<style lang="scss" scoped>
// .content {
//     display: flex;
//     align-items: center;
//     width: 100%;
// }


</style>